import React, { Component, Suspense } from "react";
import { withRouter } from "react-router-dom";

// Layout Components
const Topbar = React.lazy(() => import("./Topbar"));
const Footer = React.lazy(() => import("./Footer"));

class Layout extends Component {
  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return <Suspense fallback={this.Loader()}>
      <Topbar config={this.props.config}/>

      {this.props.children}

      <Footer version={this.props.version}/>

    </Suspense>;
  }
}

export default withRouter(Layout);
