import React, { Component, Suspense } from "react";
import Layout from "./components/Layout/";
import {
    Route,
    Switch,
    withRouter,
} from "react-router-dom";

import ReactGA from 'react-ga';
import versionJson from './version.json';
import 'moment/locale/tr';
import moment from 'moment';

// Import Css
import "./assets/css/materialdesignicons.min.css";
import "./Apps.scss";

import "./assets/css/colors/default.css";

// Include Routes
import routes from "./routes";

class MainSite extends Component {

    MainSite(props) {
        moment.locale('tr');
    }

    componentDidMount() {
        function gA(location) {
            var p = location.pathname;
            if (location.search != "")
                p += "?" + location.search;
            ReactGA.pageview(p);
        }
        if (this.props.config.gAnalyticsId && this.props.history) {
            ReactGA.initialize(this.props.config.gAnalyticsId, {
                gaOptions: {
                    titleCase: false
                }
            });
            this.props.history.listen((location, action) => {
                gA(location);
                //localStorage.setItem("lastview", p);
            });
            if (this.props.location.pathname != "") {
                gA(this.props.location);
            }
        }
        document.body.classList = "";
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    // Make sure to remove the DOM listener when the component is unmounted.
    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var doc = document.documentElement;
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (top > 80) {
            document.getElementById("topnav").classList.add("nav-sticky");
        } else {
            document.getElementById("topnav").classList.remove("nav-sticky");
        }
    };

    Loader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return <Layout config={this.props.config} version={versionJson}>
            <Suspense fallback={this.Loader()}>
                <Switch>
                    {routes.map((route, idx) =>
                        <Route
                            path={route.path}
                            exact={route.exact}
                            component={route.component}
                            render={route.render}
                            key={idx}
                        />
                    )}
                </Switch>
            </Suspense>
        </Layout>;
    }
}

export default withRouter(MainSite);