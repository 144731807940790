import React from "react";


const Home = React.lazy(() => import("./view/Home"));
const PageNotFound = React.lazy(() => import("./view/PageNotFound"));
const Advertisement = React.lazy(() => import("./view/Advertisement"));

const Profile = React.lazy(() => import("./view/account/Profile"));
const UserAddress = React.lazy(() => import("./view/account/UserAddress"));
const UserOrders = React.lazy(() => import("./view/account/UserOrders"));
const UserOrderDetail = React.lazy(() => import("./view/account/UserOrderDetail"));
const UserOrdersB2B = React.lazy(() => import("./view/account/accountb2b/UserOrdersB2B"));
const UserOrderDetailB2B = React.lazy(() => import("./view/account/accountb2b/UserOrderDetailB2B"));
const UserExtreB2B = React.lazy(() => import("./view/account/accountb2b/UserExtreB2B"));

const CartCheckout = React.lazy(() => import("./view/transaction/CartCheckout"));
const DeliveryCheckout = React.lazy(() => import("./view/transaction/DeliveryCheckout"));
const DeliveryCheckoutB2B = React.lazy(() => import("./view/transaction/DeliveryCheckoutB2B"));
const OrderComplete = React.lazy(() => import("./view/transaction/OrderComplete"));

const DynamicLink = React.lazy(() => import("./view/store/DynamicLink"));

const Categories = React.lazy(() => import("./view/admin/Categories"));

const Campaigns = React.lazy(() => import("./view/admin/Campaigns"));
const TenantMonthlySaleTotals = React.lazy(() => import("./view/admin/TenantMonthlySaleTotals"));

const CategoryBrowser = React.lazy(() => import("../src/components/Shared/CategoryBrowser"));

const ProductFilters = React.lazy(() => import("../src/components/Shared/ProductFilters"));

const Basket = React.lazy(() => import("../src/components/Shared/Basket"));

const AboutUs = React.lazy(() => import("../src/components/Shared/AboutUs"));

const routes = [
    { path: "/", exact: true, component: Home },
    { path: "/login", component: Home },
    { path: "/loginb2b", component: Home },
    { path: "/signup", component: Home },
    { path: "/utmlanding", component: Advertisement },
    { path: "/profile", component: Profile },
    { path: "/userorderdetail", component: UserOrderDetail },

    { path: "/useraddress", component: UserAddress },
    { path: "/aboutus", component: AboutUs },
    { path: "/tenantmonthlysaletotals", component: TenantMonthlySaleTotals },
    { path: "/categories", component: Categories },
    { path: "/userorders", component: UserOrders },
    { path: "/userordersb2b", component: UserOrdersB2B },
    { path: "/userorderdetailb2b", component: UserOrderDetailB2B },
    { path: "/userextreb2b", component: UserExtreB2B },
    { path: "/campaigns", component: Campaigns },
    { path: "/cartcheckout", component: CartCheckout },
    { path: "/deliverycheckout", component: DeliveryCheckout },
    { path: "/deliverycheckoutb2b", component: DeliveryCheckoutB2B },
    { path: "/ordercomplete", component: OrderComplete },

    { path: "/open", component: DynamicLink },
    { path: "/product", component: DynamicLink },

    { path: "/manual", component: () => { window.open('https://12yazilim.com/burdanbi-egitim-rehberi/'); window.location = '/'; return null; } },

    { path: "/CategoryBrowser", component: CategoryBrowser },

    { path: "/ProductFilters", component: ProductFilters },

    { path: "/Basket", component: Basket },

    { path: "*", component: PageNotFound },

];

export default routes;