import React from "react";
import ReactDOM from "react-dom";
import MainSite from "./MainSite";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Config } from './config';
import { setupApiOptions } from './service/FetchUtil';
import './i18n';

setupApiOptions({ credentials: 'include', baseUrl: Config.apiHost, contentType : 'text/plain' });

const app = (
  <BrowserRouter>
    <MainSite config={Config}/>
  </BrowserRouter>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();